<template>
    <div class="download-page-phone">
        <div class="page">
             <img src="./images/phone/bg-phone.png" class="bg-img">
             <div class="page-content">
                <div class="page-content-left">
                    <div class="page-content-title">
                        优学派教师端-教师机移动端
                    </div>
                    <div class="page-content-intr">
                        移动教学的好助手，支持作业、诊断、打卡、点评等功能
                    </div>
                    <div class="page-content-btn">
                        <div class="qcode" v-if="QRImgUrl">
                            <img :src="QRImgUrl" alt="">
                        </div>
                        <div style="align-self: center;">
                            <div class="Android-btn btn-item" @click="download('com.anoah.uclass.mobile.teacher')">
                                <span class="icon"></span>
                                <div>
                                    <p>Android<span style="font-size:20px;">版下载</span></p>
                                    <span class="tip">（Android 5.0及以上）</span>
                                </div>
                            </div>
                            <div class="iPhone-btn btn-item" @click="loadIos">
                                <span class="icon"></span>
                                <div>
                                    <p>iPhone<span style="font-size:20px;">版下载</span></p>
                                    <span class="tip">（iOS 10及以上）</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="page-content-right">
                    <img src="./images/phone/img1.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import mixins from './utils.js';
import QRCode from 'qrcode';
export default {
    name: 'DownloadPhone',
    mixins: [mixins],
    data () {
        return {
            QRImgUrl: '',
            iosLoadUrl: 'https://apps.apple.com/cn/app/优学派教师端/id1618364992',
        };
    },
    computed: {
        QRlink () {
            let url = 'yxp-pad/registration/download_jumper/teacher_mobile.html?package=com.anoah.uclass.mobile.teacher';
            if (this.iosLoadUrl) {
                url += `&jumpUrl=${this.iosLoadUrl}`;
            }
            if (window.location.origin.indexOf('localhost') !== -1 || /\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}/.test(window.location.hostname)) {
                url = 'https://youxueketang.anoah.com/' + url;
            } else if (window.location.host === 'www.youxueketang.com') {
                url = 'https://youxueketang.anoah.com/' + url;
            } else {
                url = window.location.origin + '/' + url;
            }
            return url;
        },
    },
    mounted () {
        this.getQRcode();
    },
    methods: {
        getQRcode () {
            let option = {
                errorCorrectionLevel: 'L', // 容错级别
                type: 'image/png', // 生成的二维码类型
                quality: 0.3, // 二维码质量
                margin: 3, // 二维码留白边距
                width: 180, // 宽
                height: 180, // 高
                text: '', // 二维码内容
                color: {
                    dark: '#333', // 前景色
                    light: '#fff', // 背景色
                },
            };
            QRCode.toDataURL(this.QRlink, option, (err, url) => {
                if (err) throw err;
                this.QRImgUrl = url;
            });
        },
        loadIos () {
            let _this = this;
            window.yxpapi.buriedPoint({
                method: 'recordIgnoreUser',
                module: '076',
                code: '004',
            });
            window.open(_this.iosLoadUrl, '_blank');
        },
    },
};
</script>
<style lang="scss" scoped>
 @import "./common.scss";
.download-page-phone{
    height: 100%;
    width: 100%;
    .page{
        // background-image: url(./images/phone/bg-phone.png);
        .page-content-right{
            margin-top: -4vh;
        }
        .Android-btn{
            margin-bottom: 20px;
            .icon{
                width: 40px;
                height: 46px;
                margin-right: 19px;
                background-image: url(./images/Android.png);
            }

        }
        .iPhone-btn{
            .icon{
                width: 36px;
                height: 44px;
                margin-right: 19px;
                background-image: url(./images/iPhone.png);
            }
        }
        .qcode{
            width: 180px;
            height: 180px;
            background: #fff;
            margin-right: 25px;
            align-items: center;
        }
        .page-content-intr{
            margin-bottom: 50px;
        }
    }
}
</style>
