export default {
    data () {
        return {
            downloadUrl: '',
            host: 'https://youxueketang.anoah.com',
            // host: 'http://m5.anoah.com',
        };
    },
    methods: {
        getDownloadUrl (params) {
            return new Promise((resolve, reject) => {
                let xhr = new XMLHttpRequest();
                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4 && xhr.status === 200) {
                        let res = JSON.parse(xhr.responseText);
                        if (res.errcode === 0) {
                            resolve(res.data);
                        } else {
                            reject(new Error(res.errmsg));
                        }
                    } else if (xhr.readyState === 4 && xhr.status !== 200) {
                        reject(new Error('获取下载路径失败，请重试'));
                    }
                };
                xhr.open('GET', `${this.host}/api/operation/application/app/getLastVersion?packageName=${params.packageName}&publishType=1`, true);
                xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                // xhr.setRequestHeader('Authorization', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MjA2Mzg4NjEsInRva2VuVHlwZSI6Imp3dCIsInVzZXJJZCI6MTQ4OH0.iajuKqnGrZP-_5hgXiKrwDDwyRJW41wT66gjLkflo4o');
                xhr.send();
            });
        },
        async download (packageName) {
            window.yxpapi.buriedPoint({
                method: 'recordIgnoreUser',
                module: '076',
                code: packageName === 'iclass' ? '001' : '002',
            });
            try {
                let downloadInfo = await this.getDownloadUrl({
                    packageName: packageName,
                });
                // console.log(this.convertUrl(downloadInfo.value.filePath));
                this.doDownload(this.convertUrl(downloadInfo.value.filePath));
            } catch (e) {
                this.$message.error(e.message);
            }
        },
        convertUrl (url) {
            let downloadUrl = url.replace(/https:\/\/yxp-cdn.anoah.com\/yxp-edu\/(\.*)/, '$1');
            downloadUrl = `https://youxueketang.anoah.com/file/download/${downloadUrl}?withOriginName=true`;
            return downloadUrl;
        },
        doDownload (url) {
            let a = document.createElement('a');
            let arr = url.split('/');
            a.download = arr[arr.length - 1];
            a.href = url;
            a.click();
            a.remove();
        },
    },
};
